
.character {
    padding-top:1rem;
    position:relative;
    /* height:auto; */
    width:95%;
    text-decoration: none;
}
.hover:hover {
    opacity:.75
}
.image-content {
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid white;
    border-radius:15px;
}
.character-img {
    flex-shrink: 0;
    /* min-width: 100%; */
    width:100%;
    /* min-height: 100%; */
}
.character-name {
    color:white;
    font-size:2rem;
    padding-top:.5rem;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-around;
}
.column-flex {
    display:flex;
    flex-direction:column;
    align-items:center;
}